




















import { defineComponent } from "@vue/composition-api";
import eventBus from "@monorepo/utils/src/eventBus";
import { MODAL_EVENT_BUS_ACTIONS } from "@monorepo/utils/src/eventBus/events/modal";
import { formModalPayload } from "@monorepo/utils/src/eventBus/utils/formModalPayload";
import { NotifyPriorities } from "@monorepo/app/src/types/NotifyPriorities";

export default defineComponent({
  name: "NotificationModal",
  data() {
    return {
      NotifyPriorities,
    };
  },
  props: {
    cbOnDelete: {
      type: Function,
      default: () => 1,
    },
    notification: {
      type: Object,
      required: true,
    },
  },
  methods: {
    closeModal() {
      eventBus.$emit(MODAL_EVENT_BUS_ACTIONS.TOGGLE_MODAL, formModalPayload(false, ""));
      (this as { cbOnDelete: (id: string) => void }).cbOnDelete?.(this.notification?.guid);
    },
  },
});
